<!--
AsyncContentMixin
Requires implementation of the following methods: loadHandler
Provides: formState
-->
<script>
export default {
  data () {
    return {
      private__error: null, // Error message
      private__pending: false, // API request is currently pending
      private__ready: false
    }
  },
  computed: {
    contentState () {
      return {
        pending: this.private__pending,
        error: this.private__error,
        ready: this.private__ready
      }
    }
  },
  mounted () {
    this.refreshContent()
  },
  methods: {
    async refreshContent () {
      this.private__pending = true
      try {
        await this.loadContentHandler()
        this.private__ready = true
      } catch (error) {
        this.private__error = error
      }
      this.private__pending = false
      this.$emit('loaded')
    }
  }
}
</script>
