<i18n>
{
  "de": {
    "introTitle": "Absenkpfad",
    "ghgS1Section": "Scope 1-Emissionen",
    "ghgSection": "Scope 2-Emissionen",
    "peSection": "Primärenergie",
    "eeSection": "Endenergie",
    "heatSection": "Wärme",
    "electricitySection": "Elektrizität",
    "costsSection": "Kosten"
  }
}
</i18n>

<template>
  <main-layout :portfolio="portfolio" :ready="portfolioReady && portfolioBuildingsReady" class="report-page">
    <template #default>
      <h1>{{ portfolio.name }} - {{ $t('introTitle') }}</h1>
      <scenario-picker v-model="selectedScenario" :portfolio="portfolio" />
      <portfolio-search :portfolio="portfolio" class="portfolio-search" />
      <tag-filters :portfolio="portfolio" class="tag-filters" />
      <tabs class="report-tabs" v-if="selectedScenario">
        <tab :title="$t('ghgS1Section')" v-if="portfolio.enabled_features.KPI">
          <kpi-section :kpi-type="'ghg_s1'" :portfolio="portfolio" :scenario="selectedScenario" />
        </tab>
        <tab :title="$t('ghgSection')" v-if="portfolio.enabled_features.KPI">
          <kpi-section :kpi-type="'ghg'" :portfolio="portfolio" :scenario="selectedScenario" />
        </tab>
        <tab :title="$t('peSection')" v-if="portfolio.enabled_features.KPI">
          <kpi-section :kpi-type="'pe'" :portfolio="portfolio" :scenario="selectedScenario" />
        </tab>
        <tab :title="$t('eeSection')" v-if="portfolio.enabled_features.KPI">
          <kpi-section :kpi-type="'ee'" :portfolio="portfolio" :scenario="selectedScenario" />
        </tab>
        <tab :title="$t('heatSection')" v-if="portfolio.enabled_features.KPI">
          <heat-section :portfolio="portfolio" :scenario="selectedScenario" />
        </tab>
        <tab :title="$t('electricitySection')">
          <electricity-section :portfolio="portfolio" :scenario="selectedScenario" />
        </tab>
        <tab :title="$t('costsSection')">
          <costs-section :portfolio="portfolio" :scenario="selectedScenario" />
        </tab>
      </tabs>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'
import PortfolioBuildingsMixin from '@/pages/vuex-mixins/PortfolioBuildingsMixin'

import ScenarioPicker from '@/components/shared/ScenarioPicker'
import PortfolioSearch from '@/components/portfolio/PortfolioSearch'
import TagFilters from '@/components/portfolio/filters/TagFilters'
import KpiSection from '@/components/report/sections/KpiSection'
import HeatSection from '@/components/report/sections/HeatSection'
import ElectricitySection from '@/components/report/sections/ElectricitySection'
import CostsSection from '@/components/report/sections/CostsSection'
import Tabs from '@/components/shared/Tabs'
import Tab from '@/components/shared/Tab'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
    PortfolioBuildingsMixin // Provides: portfolio.buildings, portfolioBuildingsLoading, portfolioBuildingsReady
  ],
  data () {
    return {
      selectedScenario: null
    }
  },
  components: {
    MainLayout,
    Tabs,
    Tab,
    ScenarioPicker,
    KpiSection,
    HeatSection,
    ElectricitySection,
    CostsSection,
    PortfolioSearch,
    TagFilters
  }
}
</script>

<style>

.report-tabs > .tabs > .tabs-title {
  font-weight: 700;
}
.report-page .tag-filters {
  padding-top: var(--spacing-m);
}

.report-page .scenario-picker {
  margin-bottom: var(--spacing-s);
}
</style>
