<i18n>
{
  "de": {
    "loadingMessage": "Daten werden geladen. Bitte warten...",
    "errorMessage": "Beim Laden der Daten ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
  }
}
</i18n>

<template>
  <div :class="classes">
    <div v-if="contentState.error">
      {{ $t('errorMessage') }}
    </div>
    <div v-if="contentState.pending">
      {{ $t('loadingMessage') }}
    </div>
    <div v-if="contentState.ready && !contentState.pending && !contentState.error">
      <slot></slot>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    contentState: {
      type: Object,
      required: true
    }
  },
  computed: {
    classes () {
      return {
        'async-content-container-message': this.contentState.pending || this.contentState.error
      }
    }
  }
}
</script>

<style>
.async-content-container-message {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
}
</style>
