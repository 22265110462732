<i18n>
{
  "de": {
    "yLabel": "Kapital (CHF)",
    "types": {
      "subsidies": "Einmalvergütung",
      "operation_costs": "Betriebskosten",
      "revenue_ownuse": "Reduktion Netzbezug",
      "revenue_feedin": "Einspeisung",
      "investment": "Investitionskosten"
    }
  }
}
</i18n>
<template>
  <div class="scenario-pv-npv-chart">
    <exportable-bar-chart :chart-data="chartData" :options="options" />
  </div>
</template>
<script>
import colorPalettes from '@/services/color-palettes'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  computed: {
    options () {
      const self = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        layout: {
          padding: {
            bottom: 50 // workaround to ensure tooltip has enough space
          }
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return self.formatNumber(value, 0)
              }
            },
            scaleLabel: {
              display: true,
              labelString: this.$t('yLabel')
            }
          }]
        },
        tooltips: {
          mode: 'index',
          callbacks: {
            label: (tooltipItem, data) => `${data.datasets[tooltipItem.datasetIndex].label}: ${self.formatNumber(tooltipItem.yLabel, 0)} CHF`
          }
        },
        legend: {
          position: 'bottom',
          align: 'start',
          reverse: true
        }
      }
    },
    buildings () {
      return [...this.portfolio.buildings.filter(b => b.measure_totals !== null)]
    },
    chartData () {
      let datasets = []
      datasets.push({
        label: this.$t(`types.investment`),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.measure_totals.find(mt => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) - (r.pv_npv === null ? 0 : r.pv_npv.investment)
          })
        }, undefined),
        backgroundColor: colorPalettes.pvNpvColors['investment'],
        borderColor: colorPalettes.pvNpvColors['investment'],
        stack: 'SPENDING'
      })
      datasets.push({
        label: this.$t(`types.subsidies`),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.measure_totals.find(mt => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + (r.pv_npv === null ? 0 : r.pv_npv.subsidies)
          })
        }, undefined),
        backgroundColor: colorPalettes.pvNpvColors['subsidies'],
        borderColor: colorPalettes.pvNpvColors['subsidies'],
        stack: 'INCOME'
      })
      datasets.push({
        label: this.$t(`types.revenue_ownuse`),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.measure_totals.find(mt => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + (r.pv_npv === null ? 0 : r.pv_npv.revenue_ownuse)
          })
        }, undefined),
        backgroundColor: colorPalettes.pvNpvColors['revenue_ownuse'],
        borderColor: colorPalettes.pvNpvColors['revenue_ownuse'],
        stack: 'INCOME'
      })
      datasets.push({
        label: this.$t(`types.revenue_feedin`),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.measure_totals.find(mt => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + (r.pv_npv === null ? 0 : r.pv_npv.revenue_feedin)
          })
        }, undefined),
        backgroundColor: colorPalettes.pvNpvColors['revenue_feedin'],
        borderColor: colorPalettes.pvNpvColors['revenue_feedin'],
        stack: 'INCOME'
      })
      datasets.push({
        label: this.$t(`types.operation_costs`),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.measure_totals.find(mt => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) - (r.pv_npv === null ? 0 : r.pv_npv.operation_costs)
          })
        }, undefined),
        backgroundColor: colorPalettes.pvNpvColors['operation_costs'],
        borderColor: colorPalettes.pvNpvColors['operation_costs'],
        stack: 'SPENDING'
      })
      return {
        labels: this.portfolio.scenarios.map(s => s.description),
        datasets: datasets
      }
    }
  },
  components: {
    ExportableBarChart
  }
}
</script>
<style>
.scenario-pv-npv-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
