<i18n>
{
  "de": {
    "yLabels": {
      "ghg": "Scope 2-Emissionen (kg/m\u00B2)",
      "ghg_s1": "Scope 1-Emissionen (kg/m\u00B2)",
      "pe": "Primärenergie (kWh/m\u00B2)",
      "ee": "Endenergie (kWh/m\u00B2)"
    },
    "units": {
      "ghg": "kg/m\u00B2",
      "ghg_s1": "kg/m\u00B2",
      "pe": "kWh/m\u00B2",
      "ee": "kWh/m\u00B2"
    },
    "modelLabel": "Modell"
  }
}
</i18n>

<template>
  <async-content-container :content-state="contentState" class="kpi-by-carrier-chart">
    <template #default>
      <exportable-bar-chart :chart-data="chartData" :options="options" />
    </template>
  </async-content-container>
</template>

<script>
import compassApi from '@/services/compass-api'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer'
import AsyncContentMixin from '@/components/shared/AsyncContentMixin'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'

import colorPalettes from '@/services/color-palettes'

export default {
  mixins: [AsyncContentMixin],
  components: {
    ExportableBarChart,
    AsyncContentContainer
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    query: {
      type: Object
    },
    scenario: {
      type: Object,
      required: true
    },
    kpiType: {
      type: String,
      required: true
    }
  },
  watch: {
    portfolio () {
      this.refreshContent()
    },
    scenario () {
      this.refreshContent()
    },
    query () {
      this.refreshContent()
    }
  },
  data: () => {
    return {
      projectionsByCarrier: null,
      legendItemsHidden: []
    }
  },
  methods: {
    async loadContentHandler () {
      const query = JSON.stringify(this.query)
      this.projectionsByCarrier = await compassApi.call(`/projections/${this.portfolio.id}/${this.kpiType}/by_carrier/${this.scenario.id}?query=${query}`)
    },
    getTotal (yearIdx) {
      return this.chartData.datasets.reduce((sum, v) => sum + Math.max(0, v.data[yearIdx]), 0)
    },
    tooltipLabel (tooltipItem, data) {
      const label = data.datasets[tooltipItem.datasetIndex].label
      const amount = this.formatNumber(tooltipItem.yLabel, 1)
      const yearIdx = tooltipItem.index
      const percentage = this.formatNumber(tooltipItem.yLabel / this.getTotal(yearIdx) * 100, 0)
      if (tooltipItem.yLabel > 0) {
        return `${label}: ${amount} ${this.units} (${percentage}%)`
      } else {
        return `${label}: -`
      }
    },
    tooltipTitle (tooltipItem, data) {
      const yearIdx = tooltipItem[0].index
      const totalAmount = this.formatNumber(this.getTotal(yearIdx), 1)
      const title = this.$t('modelLabel')
      return `${title}: ${totalAmount} ${this.units} (100%)`
    },
    generateLegendLabels () {
      const carriers = this.chartData.datasets.map(d => d.carrierCode)
      const carriersWithoutDuplicates = [...new Set(carriers)]
      return carriersWithoutDuplicates.filter(c => c !== 'ELECTRICITY').map(carrier => {
        return {
          carrierCode: carrier,
          text: this.$t(`_energyCarriers.${carrier}`),
          fillStyle: colorPalettes.carrierColors[carrier],
          hidden: this.legendItemsHidden.includes(carrier)
        }
      })
    },
    onLegendItemClick (chart, e, legendItem) {
      if (this.legendItemsHidden.includes(legendItem.carrierCode)) {
        this.legendItemsHidden = this.legendItemsHidden.filter(l => l !== legendItem.carrierCode)
      } else {
        this.legendItemsHidden.push(legendItem.carrierCode)
      }
      for (let dataIdx = 0; dataIdx < chart.data.datasets.length; dataIdx++) {
        if (chart.data.datasets[dataIdx].carrierCode === legendItem.carrierCode) {
          let meta = chart.getDatasetMeta(dataIdx)
          meta.hidden = this.legendItemsHidden.includes(legendItem.carrierCode)
        }
      }
      chart.update()
    }
  },
  computed: {
    yLabel () {
      return this.$t(`yLabels.${this.kpiType}`)
    },
    units () {
      return this.$t(`units.${this.kpiType}`)
    },
    options () {
      let self = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            bottom: 50 // workaround to ensure tooltip has enough space
          }
        },
        tooltips: {
          mode: 'x',
          callbacks: {
            title: this.tooltipTitle,
            label: this.tooltipLabel
          }
        },
        legend: {
          position: 'bottom',
          align: 'start',
          reverse: true,
          labels: {
            padding: 15,
            generateLabels: this.generateLegendLabels
          },
          onClick: function (e, legendItem) {
            self.onLegendItemClick(this.chart, e, legendItem)
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: this.yLabel
            }
          }]
        }
      }
    },
    chartData () {
      const sortOrder = ['ELECTRICITY_FEEDIN', 'ELECTRICITY_GRID', 'ELECTRICITY_OWNUSE', 'WOOD_CHIPS', 'PELLET', 'SOLAR_HEAT', 'DISTRICT_HEAT', 'LOCAL_HEAT', 'HEATING_OIL', 'NATURAL_GAS']
      let datasets = []
      for (const carrier in this.projectionsByCarrier.projections) {
        if (this.projectionsByCarrier.projections.hasOwnProperty(carrier)) {
          datasets.push({
            carrierCode: carrier,
            label: this.$t(`_energyCarriers.${carrier}`),
            backgroundColor: colorPalettes.carrierColors[carrier],
            data: this.projectionsByCarrier.projections[carrier].value.map((v, i) => v / this.projectionsByCarrier.projections[carrier].area[i]),
            stack: 'Calculated'
          })
        }
      }
      datasets.sort((a, b) => sortOrder.indexOf(a.carrierCode) - sortOrder.indexOf(b.carrierCode))
      return {
        labels: this.projectionsByCarrier.years,
        datasets: datasets
      }
    }
  }
}
</script>
<style>
.kpi-by-carrier-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
/* .kpi-by-carrier-chart > div {
  position: absolute;
  top: var(--spacing-s); bottom: var(--spacing-s); left: var(--spacing-s); right: var(--spacing-s);
} */
</style>
