<i18n>
{
  "de": {
    "groupByProducer": "Nach Herkunft",
    "groupByConsumer": "Nach Verwendungszweck",
    "pvParetoChart": "Ausbau Photovoltaik",
    "pvParetoChartAll": "Ausbau Photovoltaik (optimiert)",
    "production": "Stromproduktion"
  }
}
</i18n>
<template>
  <tabs class="electricity-section">
    <tab :title="$t('groupByConsumer')">
      <kpi-by-usage-chart
        key="electricity-by-usage-chart"
        :kpi-type="'el_demand'"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </tab>
    <tab :title="$t('groupByProducer')">
      <own-use-chart
        key="electricity-demand-chart"
        demand-perspective
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </tab>
    <tab :title="$t('production')">
      <own-use-chart
        key="electricity-production-chart"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </tab>
    <tab :title="$t('pvParetoChart')" v-if="portfolio.enabled_features.PV_PROFITABILITY">
      <pv-pareto-chart
        key="pv-pareto-chart"
        :portfolio="portfolio"
        :scenario="scenario"
        @click="onParetoClick"
      />
    </tab>
    <tab :title="$t('pvParetoChartAll')" v-if="portfolio.enabled_features.PV_PROFITABILITY">
      <pv-pareto-chart
        key="pv-pareto-chart-all"
        :portfolio="portfolio"
        @click="onParetoClick"
      />
    </tab>
  </tabs>
</template>
<script>
import { mapGetters } from 'vuex'

import KpiByUsageChart from '@/components/report/charts/KpiByUsageChart'
import OwnUseChart from '@/components/report/charts/OwnUseChart'
import PvParetoChart from '@/components/report/charts/PvParetoChart'
import Tabs from '@/components/shared/Tabs'
import Tab from '@/components/shared/Tab'

export default {
  props: {
    portfolio: {
      type: Object
    },
    scenario: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      query: 'portfolio/query'
    })
  },
  methods: {
    onParetoClick (building) {
      this.$router.push({ name: 'building', params: { portfolio_id: building.portfolio_id, building_id: building.id } })
    }
  },
  components: {
    KpiByUsageChart,
    OwnUseChart,
    PvParetoChart,
    Tabs,
    Tab
  }
}
</script>
<style>
.electricity-section .pv-pareto-chart {
  height: 600px;
}
</style>
