<i18n>
{
  "de": {
    "xLabel": "Liegenschaft (sortiert nach Rentabilität der PV-Anlage)",
    "yLabel": "Deckung Jahresbedarf Gesamtportfolio (%)",
    "productionTooltip": "Ertrag Photovoltaik",
    "yieldRatioTooltip": "Rendite"
  }
}
</i18n>
<template>
  <div class="pv-pareto-chart">
    <exportable-bar-chart :chart-data="chartData" :options="options" />
  </div>
</template>
<script>
import colorPalettes from '@/services/color-palettes'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    scenario: {
      type: Object
    },
    sortby: {
      type: String,
      default: 'pv_yield_rate'
    }
  },
  methods: {
    getVal (building) {
      if (this.scenario !== undefined) {
        return building.measure_totals.find(m => m.scenario_id === this.scenario.id)
      } else {
        if (this.sortby === 'pv_yield_rate') {
          let sorted = [...building.measure_totals]
          sorted.sort((a, b) => a.pv_yield_rate < b.pv_yield_rate)
          return sorted[0]
        }
      }
    }
  },
  computed: {
    buildings () {
      let buildings = [...this.portfolio.buildings.filter(b => b.measure_totals !== null && (!this.scenario || b.measure_totals.find(m => m.scenario_id === this.scenario.id).pv_yield_rate !== null))]
      if (this.sortby === 'pv_yield_rate') {
        buildings.sort((a, b) => this.getVal(b).pv_yield_rate - this.getVal(a).pv_yield_rate)
      }
      return buildings
    },
    totalDemand () {
      return this.buildings.reduce((pv, cv) => pv + cv.el_demand, 0)
    },
    options () {
      const self = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (evt, array) => {
          if (array.length !== 0) {
            this.$emit('click', this.buildings[array[0]._index])
          }
        },
        /* aspectRatio: 3, */
        layout: {
          padding: {
            bottom: 50 // workaround to ensure tooltip has enough space
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: this.$t('xLabel')
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return self.formatNumber(value, 0)
              }
            },
            scaleLabel: {
              display: true,
              labelString: this.$t('yLabel')
            }
          }]
        },
        tooltips: {
          mode: 'index',
          displayColors: false,
          callbacks: {
            title: (tooltipItem) => {
              const building = this.buildings[tooltipItem[0].index]
              return `${building.description}`
            },
            beforeBody: (tooltipItem, data) => {
              const building = this.buildings[tooltipItem[0].index]
              return `${this.$t('productionTooltip')}: ${self.formatNumber(this.getVal(building).el_production / 1000, 0)} MWh`
            },
            label: (tooltipItem, data) => {
              const building = this.buildings[tooltipItem.index]
              return `${this.$t('yieldRatioTooltip')}: ${self.formatNumber(this.getVal(building).pv_yield_rate, 1)} %`
            }
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          align: 'start',
          reverse: true
        }
      }
    },
    chartData () {
      let datasets = []
      let series = []

      let cumulativeProduction = 0
      for (let buildingIdx = 0; buildingIdx < this.buildings.length; buildingIdx++) {
        const building = this.buildings[buildingIdx]
        // https://stackoverflow.com/questions/38703114/chart-js-plugin-for-making-waterfall-charts
        const vStart = cumulativeProduction
        cumulativeProduction += this.getVal(building).el_production
        series.push([vStart / this.totalDemand * 100, cumulativeProduction / this.totalDemand * 100])
      }

      datasets.push({
        label: this.$t('default'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: series,
        backgroundColor: this.buildings.map(b => this.portfolio.scenarios.find(s => s.id === this.getVal(b).scenario_id).color),
        borderColor: colorPalettes.measureColors['ENVELOPE']
      })

      return {
        labels: this.buildings.map((v, idx) => idx + 1),
        datasets: datasets
      }
    }
  },
  components: {
    ExportableBarChart
  }
}
</script>
<style>
.pv-pareto-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
