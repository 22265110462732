<i18n>
{
  "de": {
    "yLabels": {
      "ghg": "Scope 2-Emissionen (kg/m\u00B2)",
      "ghg_s1": "Scope 1-Emissionen (kg/m\u00B2)",
      "pe": "Primärenergie (kWh/m\u00B2)",
      "ee": "Endenergie (kWh/m\u00B2)",
      "el_demand": "Strombedarf (kWh/m\u00B2)"
    },
    "units": {
      "ghg": "kg/m\u00B2",
      "ghg_s1": "kg/m\u00B2",
      "pe": "kWh/m\u00B2",
      "ee": "kWh/m\u00B2",
      "el_demand": "kWh/m\u00B2"
    },
    "modelLabel": "Modell"
  }
}
</i18n>

<template>
  <async-content-container :content-state="contentState" class="kpi-by-usage-chart">
    <template #default>
      <exportable-bar-chart :chart-data="chartData" :options="options" />
    </template>
  </async-content-container>
</template>

<script>
import compassApi from '@/services/compass-api'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer'
import AsyncContentMixin from '@/components/shared/AsyncContentMixin'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'

import colorPalettes from '@/services/color-palettes'

export default {
  mixins: [AsyncContentMixin],
  components: {
    ExportableBarChart,
    AsyncContentContainer
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    query: {
      type: Object
    },
    scenario: {
      type: Object,
      required: true
    },
    kpiType: {
      type: String,
      required: true
    }
  },
  watch: {
    portfolio () {
      this.refreshContent()
    },
    scenario () {
      this.refreshContent()
    },
    query () {
      this.refreshContent()
    }
  },
  data: () => {
    return {
      projectionsByUsage: null
    }
  },
  methods: {
    async loadContentHandler () {
      const query = JSON.stringify(this.query)
      this.projectionsByUsage = await compassApi.call(`/projections/${this.portfolio.id}/${this.kpiType}/by_usage/${this.scenario.id}?query=${query}`)
    },
    getTotal (xIndex) {
      return this.chartData.datasets.reduce((sum, v) => sum + Math.max(0, v.data[xIndex]), 0)
    },
    tooltipLabel (tooltipItem, data) {
      const label = data.datasets[tooltipItem.datasetIndex].label
      const amount = this.formatNumber(tooltipItem.yLabel, 1)
      const yearIdx = tooltipItem.index
      const percentage = this.formatNumber(tooltipItem.yLabel / this.getTotal(yearIdx) * 100, 0)
      if (tooltipItem.yLabel > 0) {
        return `${label}: ${amount} ${this.units} (${percentage}%)`
      } else {
        return `${label}: -`
      }
    },
    tooltipTitle (tooltipItem, data) {
      const yearIdx = tooltipItem[0].index
      const totalAmount = this.formatNumber(this.getTotal(yearIdx), 1)
      const title = this.$t('modelLabel')
      return `${title}: ${totalAmount} ${this.units} (100%)`
    }
  },
  computed: {
    yLabel () {
      return this.$t(`yLabels.${this.kpiType}`)
    },
    units () {
      return this.$t(`units.${this.kpiType}`)
    },
    options () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            bottom: 50 // workaround to ensure tooltip has enough space
          }
        },
        tooltips: {
          mode: 'x',
          callbacks: {
            title: this.tooltipTitle,
            label: this.tooltipLabel
          }
        },
        legend: {
          position: 'bottom',
          align: 'start',
          reverse: true,
          labels: {
            padding: 15
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: this.yLabel
            }
          }]
        }
      }
    },
    chartData () {
      const sortOrder = ['GENERAL', 'APPLIANCES', 'LIGHTNING', 'VENTILATION', 'ROOM_HEAT', 'HOT_WATER']
      let datasets = []
      for (const usage in this.projectionsByUsage.projections) {
        datasets.push({
          usageCode: usage,
          label: this.$t(`_energyUsages.${usage}`),
          backgroundColor: colorPalettes.usageColors[usage],
          data: this.projectionsByUsage.projections[usage].value.map((v, i) => v / this.projectionsByUsage.projections[usage].area[i]),
          stack: 'Calculated'
        })
      }
      datasets.sort((a, b) => sortOrder.indexOf(a.usageCode) - sortOrder.indexOf(b.usageCode))
      return {
        labels: this.projectionsByUsage.years,
        datasets: datasets
      }
    }
  }
}
</script>
<style>
.kpi-by-usage-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
