<i18n>
{
  "de": {
    "yLabels": {
      "ghg": "Treibhausgasemissionen Scope 2 (%)",
      "ghg_s1": "Treibhausgasemissionen Scope 1 (%)",
      "pe": "Primärenergie (%)",
      "ee": "Endenergie (kWh/m\u00B2)"
    },
    "units": {
      "ghg": "t",
      "ghg_s1": "t",
      "pe": "MWh",
      "ee": "MWh"
    },
    "xLabels": {
      "first_measure_year": "Liegenschaft (sortiert nach Jahr der ersten Massnahme)",
      "reduction":  "Liegenschaft (sortiert nach Reduktionspotenzial)"
    },
    "reductionTooltip": "Reduktion"
  }
}
</i18n>
<template>
  <div class="kpi-pareto-chart">
    <exportable-bar-chart :chart-data="chartData" :options="options" />
  </div>
</template>
<script>
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    scenario: {
      type: Object,
      required: true
    },
    kpiType: {
      type: String,
      required: true
    },
    sortby: {
      type: String,
      default: 'reduction'
    }
  },
  methods: {
    getVal (building) {
      return building.measure_totals.find(m => m.scenario_id === this.scenario.id)
    }
  },
  computed: {
    buildings () {
      let buildings = [...this.portfolio.buildings.filter(b => b.measure_totals !== null)]
      if (this.sortby === 'first_measure_year') {
        buildings.sort((a, b) => this.getVal(a).first_measure_year - this.getVal(b).first_measure_year)
      } else if (this.sortby === 'reduction') {
        if (this.kpiType === 'ghg') {
          buildings.sort((a, b) => (b.ghg - this.getVal(b).ghg) - (a.ghg - this.getVal(a).ghg))
        } else if (this.kpiType === 'ghg_s1') {
          buildings.sort((a, b) => (b.ghg_s1 - this.getVal(b).ghg_s1) - (a.ghg_s1 - this.getVal(a).ghg_s1))
        } else if (this.kpiType === 'pe') {
          buildings.sort((a, b) => (b.pe - this.getVal(b).pe) - (a.pe - this.getVal(a).pe))
        } else if (this.kpiType === 'ee') {
          buildings.sort((a, b) => (b.ee - this.getVal(b).ee) - (a.ee - this.getVal(a).ee))
        }
      }
      return buildings
    },
    totalValue () {
      if (this.kpiType === 'ghg') {
        return this.buildings.reduce((pv, cv) => pv + cv.ghg, 0)
      } else if (this.kpiType === 'ghg_s1') {
        return this.buildings.reduce((pv, cv) => pv + cv.ghg_s1, 0)
      } else if (this.kpiType === 'pe') {
        return this.buildings.reduce((pv, cv) => pv + cv.pe, 0)
      } else if (this.kpiType === 'ee') {
        return this.buildings.reduce((pv, cv) => pv + cv.ee, 0)
      }
      return undefined
    },
    options () {
      const self = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (evt, array) => {
          if (array.length !== 0) {
            this.$emit('click', this.buildings[array[0]._index])
          }
        },
        /* aspectRatio: 3, */
        layout: {
          padding: {
            bottom: 50 // workaround to ensure tooltip has enough space
          }
        },
        scales: {
          xAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: this.$t(`xLabels.${this.sortby}`)
            }
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return self.formatNumber(value, 0)
              }
            },
            scaleLabel: {
              display: true,
              labelString: this.$t(`yLabels.${this.kpiType}`)
            }
          }]
        },
        tooltips: {
          mode: 'index',
          displayColors: false,
          callbacks: {
            title: (tooltipItem) => {
              const building = this.buildings[tooltipItem[0].index]
              return `${building.description}`
            },
            label: (tooltipItem, data) => {
              const building = this.buildings[tooltipItem.index]
              let reduction
              if (this.kpiType === 'ghg') {
                reduction = (building.ghg - this.getVal(building).ghg) / 1000
              } else if (this.kpiType === 'ghg_s1') {
                reduction = (building.ghg_s1 - this.getVal(building).ghg_s1) / 1000
              } else if (this.kpiType === 'pe') {
                reduction = (building.pe - this.getVal(building).pe) / 1000
              } else if (this.kpiType === 'ee') {
                reduction = (building.ee - this.getVal(building).ee) / 1000
              }
              return `${this.$t('reductionTooltip')}: ${self.formatNumber(reduction, 1)} ` + this.$t(`units.${this.kpiType}`)
            }
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          align: 'start',
          reverse: true
        }
      }
    },
    chartData () {
      let datasets = []
      let series = []

      let cumulative = this.totalValue
      for (let buildingIdx = 0; buildingIdx < this.buildings.length; buildingIdx++) {
        const building = this.buildings[buildingIdx]
        // https://stackoverflow.com/questions/38703114/chart-js-plugin-for-making-waterfall-charts
        const vStart = cumulative
        if (this.kpiType === 'ghg') {
          cumulative -= building.ghg - this.getVal(building).ghg
        } else if (this.kpiType === 'ghg_s1') {
          cumulative -= building.ghg_s1 - this.getVal(building).ghg_s1
        } else {
          cumulative -= building.pe - this.getVal(building).pe
        }
        series.push([cumulative / this.totalValue * 100, vStart / this.totalValue * 100])
      }

      datasets.push({
        label: this.$t('default'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: series,
        backgroundColor: this.scenario.color,
        borderColor: this.scenario.color
      })

      return {
        labels: this.buildings.map((v, idx) => idx + 1),
        datasets: datasets
      }
    }
  },
  components: {
    ExportableBarChart
  }
}
</script>
<style>
.kpi-pareto-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
