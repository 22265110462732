<i18n>
{
  "de": {
    "selectionComparisson": "Übersicht",
    "scenarioComparisson": "Szenarienvergleich",
    "peParetoChart": "Reduktion nach Liegenschaft",
    "peMeasuredChart": "Messwerte",
    "groupByUsage": "Nach Verwendungszweck",
    "groupByCarrier": "Nach Energieträger"
  }
}
</i18n>
<template>
  <tabs>
    <tab :title="$t('selectionComparisson')">
      <kpi-compare-selection-chart
        :key="`${kpiType}-selection-chart`"
        class="chart"
        :kpi-type="kpiType"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </tab>
    <tab :title="$t('scenarioComparisson')">
      <kpi-compare-scenario-chart
        :key="`${kpiType}-scenarios-chart`"
        class="chart"
        :kpi-type="kpiType"
        :portfolio="portfolio"
        :query="query"
      />
    </tab>
    <tab :title="$t('groupByUsage')">
      <kpi-by-usage-chart
        :key="`${kpiType}-by-usage-chart`"
        class="chart"
        :kpi-type="kpiType"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </tab>
    <tab :title="$t('groupByCarrier')">
      <kpi-by-carrier-chart
        :key="`${kpiType}-by-carrier-chart`"
        class="chart"
        :kpi-type="kpiType"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </tab>
    <tab :title="$t('peMeasuredChart')">
      <measured-kpi-chart
        :key="`${kpiType}-measured-chart`"
        :kpi-type="kpiType"
        :portfolio="portfolio"
        :query="query"
      />
    </tab>
    <tab :title="$t('peParetoChart')">
      <kpi-pareto-chart
        :key="`${kpiType}-pareto-chart`"
        :kpi-type="kpiType"
        :portfolio="portfolio"
        :scenario="scenario"
        @click="onParetoClick"
      />
    </tab>
  </tabs>
</template>
<script>
import Tabs from '@/components/shared/Tabs'
import Tab from '@/components/shared/Tab'
import KpiParetoChart from '@/components/report/charts/KpiParetoChart'
import KpiCompareScenarioChart from '@/components/report/charts/KpiCompareScenarioChart'
import KpiCompareSelectionChart from '@/components/report/charts/KpiCompareSelectionChart'
import KpiByCarrierChart from '@/components/report/charts/KpiByCarrierChart'
import KpiByUsageChart from '@/components/report/charts/KpiByUsageChart'
import MeasuredKpiChart from '@/components/report/charts/MeasuredKpiChart'

import compassApi from '@/services/compass-api'
import { mapGetters } from 'vuex'

export default {
  props: {
    portfolio: {
      type: Object
    },
    scenario: {
      type: Object
    },
    kpiType: {
      type: String, // ghg, ghg_s1, pe or ee
      required: true
    }
  },
  data: () => {
    return {
      projectionsByCarrier: null,
      projectionsByUsage: null,
      projectionsByScenario: null,
      projectionsByScenarioAll: null
    }
  },
  watch: {
    portfolio () {
      this.getProjections()
    },
    scenario () {
      this.getProjections()
    },
    tagFilters () {
      this.getProjections()
    },
    searchText () {
      this.getProjections()
    }
  },
  mounted () {
    this.getProjections()
  },
  computed: {
    ...mapGetters({
      query: 'portfolio/query'
    }),
    targets () {
      const targets = this.portfolio.targets
      if (!targets) {
        return []
      }
      return targets.map(x => {
        return {
          'year': x.year,
          'value': x.pe_total
        }
      })
    }
  },
  methods: {
    onParetoClick (building) {
      this.$router.push({ name: 'building', params: { portfolio_id: building.portfolio_id, building_id: building.id } })
    },
    async getProjections () {
      const query = JSON.stringify(this.query)
      this.projectionsByScenario = await compassApi.call(`/projections/${this.portfolio.id}/${this.kpiType}/scenarios?query=${query}`)

      // Projections for selection and all buildings
      const queryAll = JSON.stringify({})
      this.projectionsByScenarioAll = await compassApi.call(`/projections/${this.portfolio.id}/${this.kpiType}/scenarios?query=${queryAll}`)

      this.projectionsByCarrier = await compassApi.call(`/projections/${this.portfolio.id}/${this.kpiType}/by_carrier/${this.scenario.id}?query=${query}`)
      this.projectionsByUsage = await compassApi.call(`/projections/${this.portfolio.id}/${this.kpiType}/by_usage/${this.scenario.id}?query=${query}`)
    }
  },
  components: {
    Tabs,
    Tab,
    KpiParetoChart,
    KpiCompareScenarioChart,
    KpiCompareSelectionChart,
    KpiByCarrierChart,
    MeasuredKpiChart,
    KpiByUsageChart
  }
}
</script>
