<i18n>
{
  "de": {
    "yLabel": "Investitionskosten (CHF)",
    "measureWINDOWS": "Fensterersatz",
    "measureROOF": "Sanierung Dach",
    "measureBASEMENT": "Sanierung Kellerdecke",
    "measureWALLS": "Sanierung Fassade",
    "measureHEATING": "Heizungsersatz",
    "measurePV": "Installation Photovoltaik"
  }
}
</i18n>
<template>
  <div class="scenario-cost-chart">
    <exportable-bar-chart :chart-data="chartData" :options="options" />
  </div>
</template>
<script>
import colorPalettes from '@/services/color-palettes'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'

export default {
  props: {
    portfolio: {
      type: Object,
      required: true
    }
  },
  computed: {
    buildings () {
      return this.portfolio.buildings.filter(b => b.measure_totals !== null)
    },
    options () {
      const self = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        layout: {
          padding: {
            bottom: 50 // workaround to ensure tooltip has enough space
          }
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return self.formatNumber(value, 0)
              }
            },
            scaleLabel: {
              display: true,
              labelString: this.$t('yLabel')
            }
          }]
        },
        tooltips: {
          mode: 'index',
          callbacks: {
            label: (tooltipItem, data) => `${data.datasets[tooltipItem.datasetIndex].label}: ${self.formatNumber(tooltipItem.yLabel, 0)} CHF`
          }
        },
        legend: {
          position: 'bottom',
          align: 'start',
          reverse: true
        }
      }
    },
    chartData () {
      let datasets = []
      datasets.push({
        label: this.$t('measureHEATING'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.measure_totals.find(mt => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + r.total_costs.heating
          })
        }, undefined),
        backgroundColor: colorPalettes.measureColors['HEATING'],
        borderColor: colorPalettes.measureColors['HEATING']
      })
      datasets.push({
        label: this.$t('measurePV'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.measure_totals.find(mt => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + r.total_costs.pv
          })
        }, undefined),
        backgroundColor: colorPalettes.measureColors['PV'],
        borderColor: colorPalettes.measureColors['PV']
      })
      datasets.push({
        label: this.$t('measureWALLS'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.measure_totals.find(mt => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + r.total_costs.walls
          })
        }, undefined),
        backgroundColor: colorPalettes.measureColors['WALLS'],
        borderColor: colorPalettes.measureColors['WALLS']
      })
      datasets.push({
        label: this.$t('measureWINDOWS'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.measure_totals.find(mt => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + r.total_costs.windows
          })
        }, undefined),
        backgroundColor: colorPalettes.measureColors['WINDOWS'],
        borderColor: colorPalettes.measureColors['WINDOWS']
      })
      datasets.push({
        label: this.$t('measureROOF'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.measure_totals.find(mt => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + r.total_costs.roof
          })
        }, undefined),
        backgroundColor: colorPalettes.measureColors['ROOF'],
        borderColor: colorPalettes.measureColors['ROOF']
      })
      datasets.push({
        label: this.$t('measureBASEMENT'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.buildings.reduce((pv, cv) => {
          return this.portfolio.scenarios.map((s, idx) => {
            const r = cv.measure_totals.find(mt => mt.scenario_id === s.id)
            return (pv === undefined ? 0 : pv[idx]) + r.total_costs.basement
          })
        }, undefined),
        backgroundColor: colorPalettes.measureColors['BASEMENT'],
        borderColor: colorPalettes.measureColors['BASEMENT']
      })
      return {
        labels: this.portfolio.scenarios.map(s => s.description),
        datasets: datasets
      }
    }
  },
  components: {
    ExportableBarChart
  }
}
</script>
<style>
.scenario-cost-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
