<template>
  <div class="exportable-line-chart">
    <line-chart :chart-data="chartData" :options="options" />
    <button @click.prevent="exportData" class="button download-button"><img class="icon" src="/icons/download.svg"></button>
  </div>
</template>

<script>
import LineChart from '@/components/shared/charts/LineChart'
import exportService from '@/services/export-service'

export default {
  components: {
    LineChart
  },
  props: {
    chartData: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  methods: {
    exportData () {
      let data = exportService.chartToCsv(this.chartData, this.options)
      exportService.saveCsv(data.join('\n'), 'export.csv')
    }
  }
}
</script>
<style>
.exportable-line-chart {
  position: relative;
}

.exportable-line-chart .download-button {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
