<i18n>
{
  "de": {
    "yLabel": "Beheizte Fläche (m\u00B2)"
  }
}
</i18n>
<template>
  <async-content-container :content-state="contentState" class="heating-type-chart">
    <template #default>
      <exportable-bar-chart :chart-data="chartData" :options="options" />
    </template>
  </async-content-container>
</template>
<script>
import compassApi from '@/services/compass-api'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer'
import AsyncContentMixin from '@/components/shared/AsyncContentMixin'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'

import colorPalettes from '@/services/color-palettes'

export default {
  mixins: [AsyncContentMixin],
  components: {
    ExportableBarChart,
    AsyncContentContainer
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    query: {
      type: Object
    },
    scenario: {
      type: Object,
      required: true
    }
  },
  watch: {
    portfolio () {
      this.refreshContent()
    },
    scenario () {
      this.refreshContent()
    },
    query () {
      this.refreshContent()
    }
  },
  data: () => {
    return {
      projectionsByHeatingType: null
    }
  },
  methods: {
    async loadContentHandler () {
      const query = JSON.stringify(this.query)
      this.projectionsByHeatingType = await compassApi.call(`/projections/${this.portfolio.id}/area/heating_type/${this.scenario.id}?query=${query}`)
    },
    getTotal (xIndex) {
      return this.chartData.datasets.reduce((sum, v) => sum + v.data[xIndex], 0)
    },
    tooltipLabel (tooltipItem, data) {
      const label = data.datasets[tooltipItem.datasetIndex].label
      const amount = this.formatNumber(tooltipItem.yLabel, 0)
      const yearIdx = tooltipItem.index
      const percentage = this.formatNumber(tooltipItem.yLabel / this.getTotal(yearIdx) * 100, 0)
      if (tooltipItem.yLabel > 0) {
        return `${label}: ${amount} m\u00B2 (${percentage}%)`
      } else {
        return `${label}: -`
      }
    },
    tooltipTitle (tooltipItem, data) {
      const yearIdx = tooltipItem[0].index
      const totalAmount = this.formatNumber(this.getTotal(yearIdx), 0)
      return `Total: ${totalAmount} m\u00B2 (100%)`
    }
  },
  computed: {
    options () {
      const self = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            bottom: 50 // workaround to ensure tooltip has enough space
          }
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return self.formatNumber(value)
              }
            },
            scaleLabel: {
              display: true,
              labelString: this.$t('yLabel')
            }
          }]
        },
        tooltips: {
          mode: 'index',
          callbacks: {
            title: this.tooltipTitle,
            label: this.tooltipLabel
          }
        },
        legend: {
          position: 'bottom',
          align: 'start',
          reverse: true
        }
      }
    },
    chartData () {
      var datasets = []
      for (const groupType in this.projectionsByHeatingType.projections) {
        if (this.projectionsByHeatingType.projections.hasOwnProperty(groupType)) {
          datasets.push({
            carrierCode: groupType,
            label: this.$t(`_heatingTypes.${groupType}`),
            backgroundColor: colorPalettes.heatingColors[groupType],
            data: this.projectionsByHeatingType.projections[groupType].area,
            lineTension: 0.1,
            borderWidth: 0,
            pointRadius: 0,
            pointHitRadius: 10,
            borderColor: colorPalettes.heatingColors[groupType]
          })
        }
      }
      return {
        labels: this.projectionsByHeatingType.years,
        datasets: datasets
      }
    }
  }
}
</script>
<style>
.heating-type-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
