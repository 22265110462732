<i18n>
{
  "de": {
    "targetPath": "Zielpfad",
    "yLabels": {
      "ghg": "Scope 2-Emissionen (kg/m\u00B2)",
      "ghg_s1": "Scope 1-Emissionen (kg/m\u00B2)",
      "pe": "Primärenergie (kWh/m\u00B2)",
      "ee": "Endenergie (kWh/m\u00B2)",
      "rh_demand": "Raumwärmebedarf (kWh/m\u00B2)"
    },
    "units": {
      "ghg": "kg/m\u00B2",
      "ghg_s1": "kg/m\u00B2",
      "pe": "kWh/m\u00B2",
      "ee": "kWh/m\u00B2",
      "rh_demand": "kWh/m\u00B2"
    }
  }
}
</i18n>

<template>
  <async-content-container :content-state="contentState" class="kpi-compare-scenario-chart">
    <template #default>
      <exportable-line-chart :chart-data="chartData" :options="options" />
    </template>
  </async-content-container>
</template>

<script>
import compassApi from '@/services/compass-api'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer'
import AsyncContentMixin from '@/components/shared/AsyncContentMixin'
import ExportableLineChart from '@/components/shared/charts/ExportableLineChart'

export default {
  mixins: [AsyncContentMixin],
  components: {
    ExportableLineChart,
    AsyncContentContainer
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    query: {
      type: Object
    },
    kpiType: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      projectionsByScenario: null,
      projectionsByScenarioAll: null,
      roundPrecision: 1
    }
  },
  watch: {
    portfolio () {
      this.refreshContent()
    },
    query () {
      this.refreshContent()
    }
  },
  methods: {
    async loadContentHandler () {
      const query = JSON.stringify(this.query)
      this.projectionsByScenario = await compassApi.call(`/projections/${this.portfolio.id}/${this.kpiType}/scenarios?query=${query}`)
    }
  },
  computed: {
    targets () {
      const targets = this.portfolio.targets
      if (!targets || this.kpiType === 'ghg_s1' || this.kpiType === 'ee' || this.kpiType === 'rh_demand') {
        return []
      }
      return targets.map(x => {
        if (this.kpiType === 'ghg') {
          return {
            'year': x.year,
            'value': x.co2total
          }
        } else if (this.kpiType === 'pe') {
          return {
            'year': x.year,
            'value': x.pe_total
          }
        }
      })
    },
    yLabel () {
      return this.$t(`yLabels.${this.kpiType}`)
    },
    units () {
      return this.$t(`units.${this.kpiType}`)
    },
    options () {
      const self = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        layout: {
          padding: {
            bottom: 50 // workaround to ensure tooltip has enough space
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: this.yLabel
            }
          }]
        },
        tooltips: {
          // mode: 'index',
          callbacks: {
            label: (tooltipItem, data) => {
              let reductionString = ''
              if (!this.targets || tooltipItem.datasetIndex > 0) {
                reductionString = ` (${self.formatNumber((tooltipItem.yLabel - data.datasets[tooltipItem.datasetIndex].data[0]) / data.datasets[tooltipItem.datasetIndex].data[0] * 100, 0)}%)`
              }
              return `${data.datasets[tooltipItem.datasetIndex].label}: ${self.formatNumber(tooltipItem.yLabel, self.roundPrecision)} ${this.units}${reductionString}`
            }
          }
        },
        legend: {
          position: 'bottom',
          align: 'start',
          display: true,
          reverse: true
        }
      }
    },
    chartData () {
      var datasets = []
      if (this.targets) {
        datasets.push({
          label: this.$t('targetPath'),
          borderColor: 'rgba(0,0,0,0.1)',
          pointBackgroundColor: 'hsla(0, 0%, 0%, 0.8)',
          borderWidth: 6,
          fill: false,
          lineTension: 0,
          pointRadius: 3,
          spanGaps: true,
          pointHitRadius: 20,
          data: this.projectionsByScenario.years.map(year => this.targets.find(target => target['year'] === year)).map(x => x === undefined ? null : x['value'])
        })
      }

      for (const scenarioId in this.projectionsByScenario.projections) {
        const scenario = this.portfolio.scenarios.find(s => s.id === parseInt(scenarioId))
        datasets.push({
          label: scenario.description,
          borderColor: scenario.color,
          pointBackgroundColor: scenario.color,
          pointHoverRadius: 0,
          pointRadius: 1,
          borderWidth: 4,
          fill: false,
          lineTension: 0,
          pointHitRadius: 20,
          data: this.projectionsByScenario.projections[scenarioId].value.map((v, i) => v / this.projectionsByScenario.projections[scenarioId].area[i])
        })
      }
      return {
        labels: this.projectionsByScenario.years,
        datasets: datasets
      }
    }
  }
}
</script>
<style>
.kpi-compare-scenario-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
