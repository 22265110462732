<i18n>
{
  "de": {
    "yLabelDemand": "Strombedarf (kWh/m\u00B2)",
    "yLabelProduction": "Stromproduktion (kWh/m\u00B2)",
    "ownUseLabel": "Eigenverbrauch",
    "gridLabel": "Stromnetz",
    "feedinLabel": "Netzeinspeisung"
  }
}
</i18n>

<template>
  <async-content-container :content-state="contentState" class="own-use-chart">
    <template #default>
      <exportable-bar-chart :chart-data="chartData" :options="options" />
    </template>
  </async-content-container>
</template>

<script>
import compassApi from '@/services/compass-api'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer'
import AsyncContentMixin from '@/components/shared/AsyncContentMixin'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'

import colorPalettes from '@/services/color-palettes'

export default {
  mixins: [AsyncContentMixin],
  components: {
    ExportableBarChart,
    AsyncContentContainer
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    query: {
      type: Object
    },
    scenario: {
      type: Object,
      required: true
    },
    demandPerspective: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    portfolio () {
      this.refreshContent()
    },
    scenario () {
      this.refreshContent()
    },
    query () {
      this.refreshContent()
    }
  },
  data: () => {
    return {
      data: null,
      roundPrecision: 1
    }
  },
  methods: {
    async loadContentHandler () {
      const query = JSON.stringify(this.query)
      if (this.demandPerspective) {
        this.data = await compassApi.call(`/projections/${this.portfolio.id}/el_demand/by_carrier/${this.scenario.id}?query=${query}`)
      } else {
        this.data = await compassApi.call(`/projections/${this.portfolio.id}/el_production/by_carrier/${this.scenario.id}?query=${query}`)
      }
    },
    getTotal (xIndex) {
      return this.chartData.datasets.reduce((sum, v) => sum + v.data[xIndex], 0)
    }
  },
  computed: {
    options () {
      const self = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        layout: {
          padding: {
            bottom: 50 // workaround to ensure tooltip has enough space
          }
        },
        tooltips: {
          /* mode: 'index',
          position: 'nearest', */
          callbacks: {
            beforeBody: (tooltipItem, data) => `Total: ${self.formatNumber(this.getTotal(tooltipItem[0].index), this.roundPrecision)} kWh/m2 (100%)`,
            label: (tooltipItem, data) => `${data.datasets[tooltipItem.datasetIndex].label}: ${self.formatNumber(tooltipItem.yLabel, this.roundPrecision)} kWh/m2 (${self.formatNumber(tooltipItem.yLabel / this.getTotal(tooltipItem.index) * 100, 0)}%)`
          }
        },
        legend: {
          position: 'bottom',
          align: 'start',
          reverse: true,
          labels: {
            padding: 15
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            scaleLabel: {
              display: true,
              labelString: this.$t(this.demandPerspective ? 'yLabelDemand' : 'yLabelProduction')
            }
          }]
        }
      }
    },
    chartData () {
      let datasets = []
      if (this.data.projections.ELECTRICITY_OWNUSE !== undefined) {
        datasets.push({
          label: this.$t('ownUseLabel'),
          backgroundColor: colorPalettes.carrierColors['ELECTRICITY_OWNUSE'],
          data: this.data.projections.ELECTRICITY_OWNUSE.value.map((v, i) => v / this.data.projections.ELECTRICITY_OWNUSE.area[i]),
          stack: 'Calculated'
        })
      }
      if (this.demandPerspective) {
        if (this.data.projections.ELECTRICITY_GRID !== undefined) {
          datasets.push({
            label: this.$t('gridLabel'),
            backgroundColor: colorPalettes.carrierColors['ELECTRICITY_GRID'],
            data: this.data.projections.ELECTRICITY_GRID.value.map((v, i) => v / this.data.projections.ELECTRICITY_GRID.area[i]),
            stack: 'Calculated'
          })
        }
      } else {
        if (this.data.projections.ELECTRICITY_FEEDIN !== undefined) {
          datasets.push({
            label: this.$t('feedinLabel'),
            backgroundColor: colorPalettes.carrierColors['ELECTRICITY_FEEDIN'],
            data: this.data.projections.ELECTRICITY_FEEDIN.value.map((v, i) => v / this.data.projections.ELECTRICITY_FEEDIN.area[i]),
            stack: 'Calculated'
          })
        }
      }
      return {
        labels: this.data.years,
        datasets: datasets
      }
    }
  }
}
</script>
<style>
.own-use-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
