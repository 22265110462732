<i18n>
{
  "de": {
    "availability_TRUE": "Verfügbare Messdaten",
    "availability_FALSE": "Hochrechnung",
    "yLabels": {
      "amount": "Endenergie (MWh)",
      "amount_normalized": "Endenergie witterungsbereinigt (MWh)",
      "ghg": "Scope 2-Emissionen (t)",
      "ghg_s1": "Scope 1-Emissionen (t)",
      "pe": "Primärenergie (MWh)",
      "ee": "Endenergie (MWh)"
    },
    "units": {
      "amount": "MWh",
      "amount_normalized": "MWh",
      "ghg": "t",
      "ghg_s1": "t",
      "pe": "MWh",
      "ee": "MWh"
    }
  }
}
</i18n>

<template>
  <async-content-container :content-state="contentState" class="measured-kpi-chart">
    <template #default>
      <exportable-bar-chart :chart-data="chartData" :options="options" />
    </template>
  </async-content-container>
</template>

<script>
import compassApi from '@/services/compass-api'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer'
import AsyncContentMixin from '@/components/shared/AsyncContentMixin'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'

import colorPalettes from '@/services/color-palettes'

export default {
  mixins: [AsyncContentMixin],
  components: {
    ExportableBarChart,
    AsyncContentContainer
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    query: {
      type: Object
    },
    kpiType: {
      type: String,
      required: true
    }
  },
  watch: {
    portfolio () {
      this.refreshContent()
    },
    query () {
      this.refreshContent()
    }
  },
  data: () => {
    return {
      timeseries: null,
      legendItemsHidden: []
    }
  },
  methods: {
    async loadContentHandler () {
      const query = JSON.stringify(this.query)
      this.timeseries = await compassApi.call(`/projections/${this.portfolio.id}/measured/${this.kpiType}?query=${query}`)
    },
    getTotal (yearIdx) {
      return this.chartData.datasets.filter(d => d.stack === 'Measured').reduce((pv, cv) => pv + Math.max(0, cv.data[yearIdx] ? cv.data[yearIdx] : 0), 0)
    },
    tooltipLabel (tooltipItem, data) {
      const label = data.datasets[tooltipItem.datasetIndex].label
      const amount = this.formatNumber(tooltipItem.yLabel, 1)
      const yearIdx = tooltipItem.index
      const percentage = this.formatNumber(tooltipItem.yLabel / this.getTotal(yearIdx) * 100, 0)
      if (tooltipItem.yLabel > 0) {
        return `${label}: ${amount} ${this.units} (${percentage}%)`
      } else {
        return `${label}: -`
      }
    },
    tooltipTitle (tooltipItem, data) {
      const yearIdx = tooltipItem[0].index
      const totalAmount = this.formatNumber(this.getTotal(yearIdx), 1)
      const year = this.timeseries.years[yearIdx]
      return `${year}: ${totalAmount} ${this.units} (100%)`
    },
    generateLegendLabels () {
      const types = this.chartData.datasets.map(d => d.meterType)
      const typesWithoutDuplicates = [...new Set(types)]
      return typesWithoutDuplicates.map(type => {
        return {
          meterType: type,
          text: this.$t(`_meterTypes.${type}`),
          fillStyle: colorPalettes.meterTypeColors[type],
          hidden: this.legendItemsHidden.includes(type)
        }
      })
    },
    onLegendItemClick (chart, e, legendItem) {
      if (this.legendItemsHidden.includes(legendItem.meterType)) {
        this.legendItemsHidden = this.legendItemsHidden.filter(l => l !== legendItem.meterType)
      } else {
        this.legendItemsHidden.push(legendItem.meterType)
      }
      for (let dataIdx = 0; dataIdx < chart.data.datasets.length; dataIdx++) {
        if (chart.data.datasets[dataIdx].meterType === legendItem.meterType) {
          let meta = chart.getDatasetMeta(dataIdx)
          meta.hidden = this.legendItemsHidden.includes(legendItem.meterType)
        }
      }
      chart.update()
    }
  },
  computed: {
    yLabel () {
      return this.$t(`yLabels.${this.kpiType}`)
    },
    units () {
      return this.$t(`units.${this.kpiType}`)
    },
    options () {
      let self = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            bottom: 50 // workaround to ensure tooltip has enough space
          }
        },
        tooltips: {
          mode: 'x',
          callbacks: {
            title: this.tooltipTitle,
            label: this.tooltipLabel
          }
        },
        legend: {
          position: 'bottom',
          align: 'start',
          reverse: true,
          /* labels: {
            padding: 15,
            generateLabels: this.generateLegendLabels
          }, */
          onClick: function (e, legendItem) {
            self.onLegendItemClick(this.chart, e, legendItem)
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return self.formatNumber(value)
              }
            },
            scaleLabel: {
              display: true,
              labelString: this.yLabel
            }
          }]
        }
      }
    },
    chartData () {
      const sortOrder = ['END_ENERGY_HEATER', 'ELECTRICITY_TENANTS', 'ELECTRICITY_GENERAL', 'ELECTRICITY_PV']
      let datasets = []
      for (const meterType in this.timeseries.timeseries) {
        if (this.timeseries.timeseries.hasOwnProperty(meterType)) {
          datasets.push({
            meterType: meterType,
            label: this.$t(`_meterTypes.${meterType}`),
            backgroundColor: colorPalettes.meterTypeColors[meterType],
            data: this.timeseries.timeseries[meterType].value.map((v, i) => v / 1000),
            // data: this.timeseries.timeseries[meterType].value.map((v, i) => v / this.timeseries.timeseries[meterType].area[i]),
            stack: 'Measured'
          })
        }
      }

      datasets.push({
        label: this.$t('availability_TRUE'),
        backgroundColor: 'hsl(230, 20%, 80%)',
        data: this.timeseries.total_available.map((v, i) => v / 1000),
        stack: 'Availability'
      })
      datasets.push({
        label: this.$t('availability_FALSE'),
        backgroundColor: 'hsl(230, 20%, 90%)',
        data: this.timeseries.total_filled.map((v, i) => v / 1000),
        stack: 'Availability'
      })

      datasets.sort((a, b) => sortOrder.indexOf(a.type) - sortOrder.indexOf(b.type))
      return {
        labels: this.timeseries.years,
        datasets: datasets
      }
    }
  }
}
</script>
<style>
.measured-kpi-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
