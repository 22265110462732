<i18n>
{
  "de": {
    "selectScenario": "Szenario wählen:"
  }
}
</i18n>

<template>
  <div class="scenario-picker">
    <dropdown-menu left class="scenario-picker-dropdown">
      <template v-slot:title>
        <span class="select-scenario">{{ $t('selectScenario') }}</span>
        <scenario-icon :color="model.color" /> {{ model.description }}
      </template>
      <template #contents>
        <li v-for="scenario in portfolio.scenarios" :key="scenario.id">
          <a href="#" @click.prevent="selectScenario(scenario)"><scenario-icon :color="scenario.color" /> {{ scenario.description }}</a>
        </li>
      </template>
    </dropdown-menu>
  </div>
</template>

<script>
import DropdownMenu from './DropdownMenu'
import ScenarioIcon from '@/components/shared/ScenarioIcon'

export default {
  props: {
    portfolio: {
      type: Object
    },
    value: {
      type: Object
    }
  },
  data: () => {
    return {
      model: null
    }
  },
  watch: {
    value () {
      this.model = this.value
    }
  },
  created: function () {
    if (this.value) {
      this.model = this.value
    } else {
      this.model = this.portfolio.scenarios[0]
      this.$emit('input', this.model)
    }
  },
  methods: {
    selectScenario (scenario) {
      this.model = scenario
      this.$emit('input', this.model)
    }
  },
  components: {
    DropdownMenu,
    ScenarioIcon
  }
}
</script>

<style>
.scenario-picker {
  display: inline-block;
}

.scenario-picker a.dropdown-menu-title {
  font-size: var(--font-s);
  padding: var(--spacing-xxs) 0;
}

.scenario-picker .color-icon {
  display:inline-block;
  width: .8rem;
  height: .8rem;
}

.scenario-picker .scenario-picker-dropdown > a {
  padding: var(--spacing-s);
  border: var(--box-border);
  border-radius: var(--box-radius);
}

.scenario-picker .select-scenario {
  margin-right: var(--spacing-s);
}
</style>
