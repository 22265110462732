<i18n>
{
  "de": {
    "envelopeHeatDemand": "Heizwärmebedarf",
    "heatingType": "Anteil Wärmeerzeuger"
  }
}
</i18n>
<template>
  <tabs>
    <tab :title="$t('heatingType')">
      <heating-type-chart
        key="heat-by-type-chart"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </tab>
    <tab :title="$t('envelopeHeatDemand')">
      <kpi-compare-scenario-chart
        key="heat-scenarios-chart"
        :kpi-type="'rh_demand'"
        :portfolio="portfolio"
        :query="query"
      />
    </tab>
  </tabs>
</template>
<script>
import KpiCompareScenarioChart from '@/components/report/charts/KpiCompareScenarioChart'
import HeatingTypeChart from '@/components/report/charts/HeatingTypeChart'
import Tabs from '@/components/shared/Tabs'
import Tab from '@/components/shared/Tab'

import { mapGetters } from 'vuex'

export default {
  props: {
    portfolio: {
      type: Object
    },
    scenario: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      query: 'portfolio/query'
    })
  },
  components: {
    KpiCompareScenarioChart,
    HeatingTypeChart,
    Tabs,
    Tab
  }
}
</script>
