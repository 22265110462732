<i18n>
{
  "de": {
    "yLabel": "Investitionskosten (CHF)",
    "measureWINDOWS": "Fensterersatz",
    "measureROOF": "Sanierung Dach",
    "measureBASEMENT": "Sanierung Kellerdecke",
    "measureWALLS": "Sanierung Fassade",
    "measureHEATING": "Heizungsersatz",
    "measurePV": "Installation Photovoltaik"
  }
}
</i18n>
<template>
  <async-content-container :content-state="contentState" class="heating-type-chart">
    <template #default>
      <exportable-bar-chart :chart-data="chartData" :options="options" />
    </template>
  </async-content-container>
</template>
<script>
import compassApi from '@/services/compass-api'

import AsyncContentContainer from '@/components/shared/AsyncContentContainer'
import AsyncContentMixin from '@/components/shared/AsyncContentMixin'
import ExportableBarChart from '@/components/shared/charts/ExportableBarChart'

import colorPalettes from '@/services/color-palettes'

export default {
  mixins: [AsyncContentMixin],
  components: {
    ExportableBarChart,
    AsyncContentContainer
  },
  props: {
    portfolio: {
      type: Object,
      required: true
    },
    query: {
      type: Object
    },
    scenario: {
      type: Object,
      required: true
    }
  },
  watch: {
    portfolio () {
      this.refreshContent()
    },
    scenario () {
      this.refreshContent()
    },
    query () {
      this.refreshContent()
    }
  },
  data: () => {
    return {
      data: null
    }
  },
  methods: {
    async loadContentHandler () {
      const query = JSON.stringify(this.query)
      this.data = await compassApi.call(`/projections/${this.portfolio.id}/costs/by_type/${this.scenario.id}?query=${query}`)
    }
  },
  computed: {
    options () {
      const self = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        /* aspectRatio: 3, */
        layout: {
          padding: {
            bottom: 50 // workaround to ensure tooltip has enough space
          }
        },
        scales: {
          xAxes: [{
            stacked: true
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return self.formatNumber(value, 0)
              }
            },
            scaleLabel: {
              display: true,
              labelString: this.$t('yLabel')
            }
          }]
        },
        tooltips: {
          mode: 'index',
          callbacks: {
            label: (tooltipItem, data) => `${data.datasets[tooltipItem.datasetIndex].label}: ${self.formatNumber(tooltipItem.yLabel, 0)} CHF`
          }
        },
        legend: {
          position: 'bottom',
          align: 'start',
          reverse: true
        }
      }
    },
    chartData () {
      let datasets = []
      datasets.push({
        label: this.$t('measureHEATING'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.projections.heating.costs,
        backgroundColor: colorPalettes.measureColors['HEATING'],
        borderColor: colorPalettes.measureColors['HEATING']
      })
      datasets.push({
        label: this.$t('measurePV'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.projections.pv.costs,
        backgroundColor: colorPalettes.measureColors['PV'],
        borderColor: colorPalettes.measureColors['PV']
      })
      datasets.push({
        label: this.$t('measureWALLS'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.projections.walls.costs,
        backgroundColor: colorPalettes.measureColors['WALLS'],
        borderColor: colorPalettes.measureColors['WALLS']
      })
      datasets.push({
        label: this.$t('measureWINDOWS'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.projections.windows.costs,
        backgroundColor: colorPalettes.measureColors['WINDOWS'],
        borderColor: colorPalettes.measureColors['WINDOWS']
      })
      datasets.push({
        label: this.$t('measureROOF'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.projections.roof.costs,
        backgroundColor: colorPalettes.measureColors['ROOF'],
        borderColor: colorPalettes.measureColors['ROOF']
      })
      datasets.push({
        label: this.$t('measureBASEMENT'),
        lineTension: 0.1,
        borderWidth: 0,
        pointRadius: 0,
        pointHitRadius: 10,
        data: this.data.projections.basement.costs,
        backgroundColor: colorPalettes.measureColors['BASEMENT'],
        borderColor: colorPalettes.measureColors['BASEMENT']
      })
      return {
        labels: this.data.years,
        datasets: datasets
      }
    }
  }
}
</script>
<style>
.cost-chart {
  min-height: 250px;
  width: 100%;
  border: var(--box-border);
  border-radius: var(--box-radius);
  padding: var(--spacing-s);
}
</style>
