<i18n>
{
  "de": {
    "investmentCostsComparisson": "Szenarienvergleich",
    "investmentCostsPath": "Übersicht",
    "pvNpvComparisson": "Wirtschaftlichkeit Photovoltaik"
  }
}
</i18n>
<template>
  <tabs class="costs-section">
    <tab :title="$t('investmentCostsPath')">
      <cost-chart
        key="cost-chart"
        :portfolio="portfolio"
        :query="query"
        :scenario="scenario"
      />
    </tab>
    <tab :title="$t('investmentCostsComparisson')">
      <scenario-cost-chart
        key="scenario-cost-chart"
        :portfolio="portfolio"
      />
    </tab>
    <tab :title="$t('pvNpvComparisson')" v-if="portfolio.enabled_features.PV_PROFITABILITY">
      <scenario-pv-npv-chart
        key="scenario-pv-npv-chart"
        :portfolio="portfolio"
      />
    </tab>
  </tabs>
</template>

<script>
import { mapGetters } from 'vuex'

import CostChart from '@/components/report/charts/CostChart'
import ScenarioCostChart from '@/components/report/charts/ScenarioCostChart'
import ScenarioPvNpvChart from '@/components/report/charts/ScenarioPvNpvChart'
import Tabs from '@/components/shared/Tabs'
import Tab from '@/components/shared/Tab'

export default {
  props: {
    portfolio: {
      type: Object
    },
    scenario: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      query: 'portfolio/query'
    }),
    sortedMeasures () {
      let measuresSorted = [...this.pvPareto.result]
      return measuresSorted.filter(m => m.pv_yield_rate !== undefined).sort((a, b) => b.pv_yield_rate - a.pv_yield_rate)
    }
  },
  components: {
    CostChart,
    ScenarioCostChart,
    ScenarioPvNpvChart,
    Tabs,
    Tab
  }
}
</script>
